export default function () {
    const masterArticles = [
        {
            title: '生命の大親『大親神大生命』',
            article:
                '大自然には、大自然のあらゆる営みをしていて下さる、大いなる意志のお力とお働きがあります。大自然の大いなる御心が働いています。\n \
                \n \
                私たち人間を含め、誰も自分の生命を自分で創る事はできません。大自然の大いなる生命は、生きとし活ける全てのものに生命をお与え下さり、この世に生物として誕生させて、生かして下さっておられます。\n \
                \n \
                大自然の大いなる生命は、全てに生命を与えて下さっておられますので、全生物の「生命の大親」です。\n \
                <ul class="disc-list-ul">\
                    <li class="disc-list-li">全生物に生命をお与え下さっている、全ての生命の「大親」である「大生命」</li>\
                    <li class="disc-list-li">全生物に絶対必要不可欠な御存在であり、人間より高次元の貴い御存在＝「神」<sup class="smaller">※</sup></li>\
                </ul>\
                という意味で、『大親神大生命』と申し上げます。\n \
                （※「神」は、今までの地球上の神仏（神）と異なる事を意味します。）',
        },
        {
            title: '『大親神大生命』は大自然の全てを\n \
                して下さっている御存在です',
            article:
                '『大親神大生命』は、私たち全生物に生命をお与え下さり、生かして下さっておられます。\n \
                『大親神大生命』の大自然の大いなる生命のお力・お働きが無ければ、全生物は誰も誕生しておりません。私たちがこの世に誕生し、0歳から今日まで生きて来れたのは、大自然の大いなる生命のお力・お働き、つまり『大親神大生命』のお力・お働きがあるからこそです。\n \
                \n \
                全生物の生存だけではありません。地球が自転し公転すること、春夏秋冬など季節が巡ること、心臓の鼓動、血液の循環、1秒間に5000万もの細胞が生まれ変わる新陳代謝…。マクロの大宇宙も、ミクロの原子や素粒子も、全ての生命の誕生や死も、運命・環境も、『大親神大生命』が行っていて下さる現象です。\n \
                大自然界の全ては『大親神大生命』の大いなる生命のお力・お働きによって営まれているのです。',
        },
        {
            title: '『大親神大生命』の御存在を分からせて頂くと、生き方が変わります',
            article:
                'これまで私たち人類は、自分たちの「生命の大親」である『大親神大生命』という貴い御存在を全く分からずに生きてまいりました。\n \
                \n \
                それはちょうど、親を見失って泣いている子供のように、生命の大親を見失った、生命的・霊的な迷子だったのです。今までの人類は、どんなに物質的に満たされても根本的に何か情緒不安定で、満たされないような、精神的に不健全な状態で生きていたのです。\n \
                \n \
                『大親神大生命』は私たち一人一人が、より幸福感・充実感に溢れた、素晴らしい一生が送れるよう、生命の愛を常に注いで下さっておられます。『大親神大生命』の貴き御存在と御心を分からせて頂くと、本当の意味での心の安らぎと安定を得て、よりプラスに発展的に生きる事ができます。\n \
                \n \
                また、人類はもとより地球上の生命ある生物の全てが、『大親神大生命』より貴い生命の一部をお与え頂いた同じ「生命の御子」であり「生命の家族」であるという事実が悟れたならば、地球上で互いの生命を粗末にしたり、生命の家族同士で対立し傷付け合うような、マイナスの生き方はしないはずです。\n \
                \n \
                私たちの人生、そして地球の未来の為にも、『大親神大生命』、大自然の大いなる生命の大親によって活かされ生きているという事実を、一日も早く理解し、『大親神大生命』に対し奉り生命の感謝と感動をもって生きて行く事が大切です。\n \
                \n \
                大自然界は、『大親神大生命』の貴き御存在をお伝えして、一人でも多くの方がより良い素晴らしい人生を送り、平和で愛情豊かな地球へと発展できますように、そのような願いと目的で啓蒙活動を行っております。\n \
                ',
        },
    ]
    return { masterArticles }
}

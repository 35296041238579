<template>
    <div id="Master">
        <section id="master-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">『大親神大生命』</h1>
            </div>

            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-2.jpg'),
                        require('@/assets/background/background-2-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="master-main" class="b25">
            <h2 class="f29 w70 hide-mb">『大親神大生命』</h2>
            <main class="article-group">
                <article
                    v-for="(item, index) in masterArticles"
                    :key="'master-article-' + index"
                    class="article-style purple w90 w80-mb"
                >
                    <h3
                        v-if="item.title"
                        v-html="wrap(item.title)"
                        class="title w60 f29 f16-mb"
                    ></h3>
                    <p
                        v-if="item.article"
                        v-html="wrap(item.article)"
                        class="article w80 f14 f12-mb"
                    ></p>
                </article>
            </main>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap } from '@/utils/common.js'
import getMasterData from '@/data/getMasterData'

export default {
    name: 'Master',
    setup() {
        const { masterArticles } = getMasterData()
        return { addBackgroundImage, wrap, masterArticles }
    },
}
</script>
<style lang="scss" scoped>
#master-main {
    padding-bottom: 50px;
    h2 {
        @include h1Style();
        margin: 140px auto 50px;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: calc(50% - 110px);
            top: -70px;
            width: 220px;
            height: 220px;
            border-radius: 110px;
            background-color: rgba($color: $majorPurple, $alpha: 0.2);
        }
    }
}
</style>
